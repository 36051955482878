import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ location }) => (
  <Layout>
    <SEO 
        pagetitle="サイトのご利用にあたって"
        pagepath={location.pathname}
    />
    <main id="policy">
        <div className="inner">
            <h1>サイトのご利用にあたって</h1>
            <p>当ウェブサイトは、甲能税理士事務所（以下、当社といいます）によって運営されています。本利用規約記載の諸条件に同意された場合のみ、当ウェブサイトをご利用ください。</p>
        </div>
        <div className="sitePolicy">
            <div className="inner">
                <h2>サイトの環境</h2>
                <h3>推奨ブラウザ</h3>
                <p>当ウェブサイトはMicrosoft Internet Explorerの利用を非推奨とさせていただきます。</p>
                <a href="https://social.msdn.microsoft.com/Forums/ja-JP/47290e24-fc66-4d3e-a2de-429643758d40/internet-explorer-12398201702446012395123881235612390">Internet Explorer の今後について</a>
                <p>Internet Explorerをご利用の方は、最新のWeb標準仕様に準拠した後継ブラウザであるMicrosoft Edge、または、以下のブラウザの最新状態でご利用することを推奨します。</p>
                <p>・Google Chrome</p>
                <p>・Safari</p>
                <p>・Mozilla Firefox</p>
                <p>ブラウザ、バージョンによっては、当ウェブサイトが正確に表示されなかったり、動作が正常ではない可能性があります。</p>
                <h3>Javascript</h3>
                <p>当ウェブサイトではJavaScriptを使用しています。お使いのブラウザのJavaScriptを有効にし、当ウェブサイトを閲覧できるようにしてください。</p>
                <h3>Cookie</h3>
                <p>当ウェブサイトではCookieを使用しております。これは当ウェブサイトの利用状況を把握することにより、当ウェブサイトのコンテンツの充実や利便性の向上を目的としたものです。お使いのブラウザの設定により、Cookieの受信、発行時の通知、および受信拒否の設定などが可能です。当社によるCookieの利用やプライバシー保護については、<Link to={`/privacypolicy/`}>個人情報の取扱い</Link>をご参照ください。</p>
            </div>
        </div>
        <div className="inner">
            <h2>利用規約</h2>
            <p>本利用規約（以下「本規約」と言います。）には、本サービスの提供条件及び当社と登録ユーザーの皆様との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。</p>
            <h3>第1条（適用）</h3>
            <p>1.本規約は、本サービスの提供条件及び本サービスの利用に関する当社と登録ユーザーとの間の権利義務関係を定めることを目的とし、登録ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。</p>
            <p>2.本規約の内容と、その他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。</p>
            <h3>第2条（定義）</h3>
            <p>本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。</p>
            <p>(1)「当社」とは、甲能税理士事務所(「GoodFinance」の運営会社)を意味します。</p>
            <p>(2)「本サービス」とは、当社が運営するWebサイト「GoodFinance」によって当社が提供する一切のサービス（理由の如何を問わずサービスの名称または内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。</p>
            <p>(3)「当社ウェブサイト」とは、そのドメインが「https://good-finance.jp」である、当社が運営するウェブサイト（理由の如何を問わず、当社のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。</p>
            <p>(4)「サービス利用契約」とは、本規約を契約条件として当社と登録ユーザーの間で締結される、本サービスの利用契約を意味します。</p>
            <p>(5)「登録ユーザー」とは、第3条（登録）に基づいて本サービスの利用者としての登録がなされた個人または法人を意味します。</p>
            <p>(6)「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。</p>
            <h3>第3条（登録）</h3>
            <p>1.本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録事項」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。</p>
            <p>2.当社は、当社の基準に従って、第１項に基づいて登録申請を行った登録希望者（以下「登録申請者」といいます。）の登録の可否を判断し、当社が登録を認める場合にはその旨を登録申請者に通知します。登録申請者の登録ユーザーとしての登録は、当社が本項の通知を行ったことをもって完了したものとします。</p>
            <p>3.前項に定める登録の完了時に、サービス利用契約が登録ユーザーと当社の間に成立し、登録ユーザーは本サービスを本規約に従い利用することができるようになります。</p>
            <p>4.当社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。</p>
            <div>
                <p>(1)当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合</p>
                <p>(2)未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合</p>
                <p>(3)反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合</p>
                <p>(4)過去当社との契約に違反した者またはその関係者であると当社が判断した場合</p>
                <p>(5)第10条に定める措置を受けたことがある場合</p>
                <p>(6)その他、登録を適当でないと当社が判断した場合</p>
            </div>
            <h3>第4条（登録事項の変更）</h3>
            <p>登録ユーザーは、登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。</p>
            <h3>第5条（パスワード及びユーザーIDの管理）</h3>
            <p>1.登録ユーザーは、自己の責任において、本サービスに関するパスワード及びユーザーIDを適切に管理及び保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。</p>
            <p>2.パスワードまたはユーザーIDの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は登録ユーザーが負うものとします。</p>
            <h3>第6条（料金及び支払方法）</h3>
            <p>登録ユーザーは、本サービス利用の対価として、別途当社が定め、当社ウェブサイトに表示する利用料金を、当社が指定する支払方法により当社に直接に支払うものとします。</p>
            <h3>第7条（禁止事項）</h3>
            <p>登録ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。</p>
            <p>(1)法令に違反する行為または犯罪行為に関連する行為</p>
            <p>(2)当社、本サービスの他の利用者またはその他の第三者に対する詐欺または脅迫行為</p>
            <p>(3)公序良俗に反する行為</p>
            <p>(4)当社、本サービスの他の利用者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為</p>
            <p>(5)本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を当社または本サービスの他の利用者に送信すること</p>
            <div>
                <p>・過度に暴力的または残虐な表現を含む情報</p>
                <p>・コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報</p>
                <p>・当社、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報</p>
                <p>・過度にわいせつな表現を含む情報</p>
                <p>・差別を助長する表現を含む情報</p>
                <p>・自殺、自傷行為を助長する表現を含む情報</p>
                <p>・薬物の不適切な利用を助長する表現を含む情報</p>
                <p>・反社会的な表現を含む情報</p>
                <p>・チェーンメール等の第三者への情報の拡散を求める情報</p>
                <p>・他人に不快感を与える表現を含む情報</p>
            </div>
            <p>(6)本サービスのネットワークまたはシステム等に過度な負荷をかける行為</p>
            <p>(7)当社が提供するソフトウェアその他のシステムに対するリバースエンジニアリングその他の解析行為</p>
            <p>(8)本サービスの運営を妨害するおそれのある行為</p>
            <p>(9)当社のネットワークまたはシステム等への不正アクセス</p>
            <p>(10)第三者に成りすます行為</p>
            <p>(11)本サービスの他の利用者のIDまたはパスワードを利用する行為</p>
            <p>(12)当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為</p>
            <p>(13)本サービスの他の利用者の情報の収集</p>
            <p>(14)当社、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為</p>
            <p>(15)当社ウェブサイト上で掲載する本サービス利用に関するルールに抵触する行為</p>
            <p>(16)反社会的勢力等への利益供与</p>
            <p>(17)面識のない異性との出会いを目的とした行為</p>
            <p>(18)前各号の行為を直接または間接に惹起し、または容易にする行為</p>
            <p>(19)前各号の行為を試みること</p>
            <p>(20)その他、当社が不適切と判断する行為</p>
            <h3>第8条（本サービスの停止等）</h3>
            <p>当社は、以下のいずれかに該当する場合には、登録ユーザーに事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。</p>
            <p>(1)本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合</p>
            <p>(2)コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合</p>
            <p>(3)地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合</p>
            <p>(4)その他、当社が停止または中断を必要と判断した場合</p>
            <h3>第9条（権利帰属）</h3>
            <p>1.当社ウェブサイト及び本サービスに関する知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、当社ウェブサイトまたは本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。</p>
            <p>2.登録ユーザーは、投稿データについて、自らが投稿その他送信することについての適法な権利を有していること、及び投稿データが第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。</p>
            <p>3.登録ユーザーは、投稿データについて、当社に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。また、他の登録ユーザーに対しても、本サービスを利用して登録ユーザーが投稿その他送信した投稿データの使用、複製、配布、派生著作物を作成、表示及び実行することについての非独占的なライセンスを付与します。</p>
            <p>4.登録ユーザーは、当社及び当社から権利を承継しまたは許諾された者に対して著作者人格権を行使しないことに同意するものとします。</p>
            <h3>第10条（登録抹消等）</h3>
            <p>1.当社は、登録ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、投稿データを削除もしくは非表示にし、当該登録ユーザーについて本サービスの利用を一時的に停止し、または登録ユーザーとしての登録を抹消することができます。</p>
            <div>
                <p>(1)本規約のいずれかの条項に違反した場合</p>
                <p>(2)登録事項に虚偽の事実があることが判明した場合</p>
                <p>(3)支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合</p>
                <p>(4)6ヶ月以上本サービスの利用がない場合</p>
                <p>(5)当社からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合</p>
                <p>(6)第3条第4項各号に該当する場合</p>
                <p>(7)その他、当社が本サービスの利用または登録ユーザーとしての登録の継続を適当でないと判断した場合</p>
            </div>
            <p>2.前項各号のいずれかの事由に該当した場合、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。</p>
            <h3>第11条（退会）</h3>
            <p>1.登録ユーザーは、当社所定の手続の完了により、本サービスから退会し、自己の登録ユーザーとしての登録を抹消することができます。</p>
            <p>2.退会にあたり、当社に対して負っている債務が有る場合は、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。</p>
            <p>3.退会後の利用者情報の取扱いについては、第15条の規定に従うものとします。</p>
            <h3>第12条（本サービスの内容の変更、終了）</h3>
            <p>1.当社は、当社の都合により、本サービスの内容を変更し、または提供を終了することができます。</p>
            <p>2.当社が本サービスの提供を終了する場合、当社は登録ユーザーに事前に通知するものとします。</p>
            <h3>第13条（保証の否認及び免責）</h3>
            <p>1.当社は、本サービスが登録ユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、登録ユーザーによる本サービスの利用が登録ユーザーに適用のある法令または業界団体の内部規則等に適合すること、継続的に利用できること、及び不具合が生じないことについて、明示又は黙示を問わず何ら保証するものではありません。</p>
            <p>2.当社は、本サービスに関して登録ユーザーが被った損害につき、責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとします。</p>
            <p>3.本サービスまたは当社ウェブサイトに関連して登録ユーザーと他の登録ユーザーまたは第三者との間において生じた取引、連絡、紛争等については、登録ユーザーが自己の責任によって解決するものとします。</p>
            <h3>第14条（秘密保持）</h3>
            <p>登録ユーザーは、本サービスに関連して当社が登録ユーザーに対して秘密に取扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取扱うものとします。</p>
            <h3>第15条（利用者情報の取扱い）</h3>
            <p>1.当社による登録ユーザーの利用者情報の取扱いについては、別途<Link to={`/privacypolicy/`}>当社プライバシーポリシー</Link>の定めによるものとし、登録ユーザーはこのプライバシーポリシーに従って当社が登録ユーザーの利用者情報を取扱うことについて同意するものとします。</p>
            <p>2.当社は、登録ユーザーが当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、登録ユーザーはこれに異議を唱えないものとします。</p>
            <h3>第16条（本規約等の変更）</h3>
            <p>当社は、当社が必要と認めた場合は、本規約を変更できるものとします。本規約を変更する場合、変更後の本規約の施行時期及び内容を当社ウェブサイト上での掲示その他の適切な方法により周知し、または登録ユーザーに通知します。但し、法令上登録ユーザーの同意が必要となるような内容の変更の場合は、当社所定の方法で登録ユーザーの同意を得るものとします。</p>
            <h3>第17条（連絡／通知）</h3>
            <p>1.本サービスに関する問い合わせその他登録ユーザーから当社に対する連絡または通知、及び本規約の変更に関する通知その他当社から登録ユーザーに対する連絡または通知は、当社の定める方法で行うものとします。</p>
            <p>2.当社が登録事項に含まれるメールアドレスその他の連絡先に連絡または通知を行った場合、登録ユーザーは当該連絡または通知を受領したものとみなします。</p>
            <h3>第18条（サービス利用契約上の地位の譲渡等）</h3>
            <p>1.登録ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。</p>
            <p>2.当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びに登録ユーザーの登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、登録ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。</p>
            <h3>第19条（分離可能性）</h3>
            <p>本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。</p>
            <h3>第20条（準拠法及び管轄裁判所）</h3>
            <p>1.本規約及びサービス利用契約の準拠法は日本法とします。</p>
            <p>2.本規約またはサービス利用契約に起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
            <div className="policyDate">2020年11月18日制定</div>
        </div>
        <div className="breadCrumb">
            <ol>
                <li>
                <Link to={`/`}>TOP</Link>
                </li>
                <li>
                    サイトのご利用にあたって
                </li>
            </ol>
        </div>
    </main>
  </Layout>
)
